<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">流量统计</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left'  size="large">
                    <el-form-item style="display:flex;" label="点击时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item>
						<el-input  :maxlength="50" style="margin-left:20px;width:300px;" v-model="storeName" placeholder="请输入商家名称" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(storeName)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
        <div class="jifen_top"><p>点击量：<span>{{hits}}</span></p></div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="storeName" label="商家名称" ></el-table-column>
				<el-table-column prop="clicknum" label="点击量" ></el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		datacountbussclickList
	} from "@/api/platform/index";
	export default {
		name: "datacountbussclickList",
		data() {
			return {
                storeName:'',
				data: [],
                hits:'0',            //点击量
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                dateBetween:'',     // 时间区间
                startTime:'',       // 开始时间
                endTime:''          // 结束时间
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
                let that = this;
				datacountbussclickList({
                    beginTime:that.startTime,
                    endTime:that.endTime,
                    storeName:that.storeName,
					page: that.page,
					size: that.pageSize,
				}).then(res => {
                    if(res.code == 200){
                        that.hits = res.data.hits;
                        that.data = res.data.flowVOIPage.records;
                        that.total = res.data.flowVOIPage.total;
                        that.currentPage = res.data.flowVOIPage.current;
                        that.pageCount = res.data.flowVOIPage.pages;
                        if (res.data.flowVOIPage.current > res.data.flowVOIPage.pages && res.data.flowVOIPage.pages != 0) {
                            that.page = res.data.flowVOIPage.pages;
                            that.list();
                        }
                    }else{
                        that.$message.error(res.msg);
                    }
				})
			},

            // 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.startTime = '';
                    this.endTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.startTime = datetime;
                this.endTime = datetime1;
            },
            
            //搜索提交
			seachSumbit(){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.jifen_top{
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    display: flex;
    align-content: center;
    justify-items: flex-start;
}
.jifen_top span{
    margin-right: 20px;
    font-weight: bold;
}
</style>